<script lang="ts">
import { documentToHtmlString } from '@contentful/rich-text-html-renderer'
import { BLOCKS, INLINES } from '@contentful/rich-text-types'

export let richText: any

const RICHTEXT_OPTIONS: any = {
  renderNode: {
    [BLOCKS.DOCUMENT]: (_node: any, next: (arg0: any) => any) =>
      `<p>${next(_node.content).replace('\n', '<br/>')}</p>`,
    [BLOCKS.PARAGRAPH]: (_node: any, next: (arg0: any) => any) =>
      `<p>${next(_node.content).replace('\n', '<br/>')}</p>`,
    [BLOCKS.HEADING_1]: (_node: any, next: (arg0: any) => any) =>
      `<h1>${next(_node.content)}</h1>`,
    [BLOCKS.HEADING_2]: (_node: any, next: (arg0: any) => any) =>
      `<h2>${next(_node.content)}</h2>`,
    [BLOCKS.HEADING_3]: (_node: any, next: (arg0: any) => any) =>
      `<h3>${next(_node.content)}</h3>`,
    [BLOCKS.HEADING_4]: (_node: any, next: (arg0: any) => any) =>
      `<h4>${next(_node.content)}</h4>`,
    [BLOCKS.HEADING_5]: (_node: any, next: (arg0: any) => any) =>
      `<h5>${next(_node.content)}</h5>`,
    [BLOCKS.HEADING_6]: (_node: any, next: (arg0: any) => any) =>
      `<h5>${next(_node.content)}</h6>`,
    [BLOCKS.EMBEDDED_ASSET]: ({
      data: {
        //@ts-ignore
        target: { fields },
      },
    }) => `<img src="${fields.file.url}" alt="${fields.description}"/>`,
    // [BLOCKS.UL_LIST]: (_node: any, next: (arg0: any) => any) => `${next(_node.content)}`,
    // [BLOCKS.OL_LIST]: (_node: any, next: (arg0: any) => any) => `${next(_node.content)}`,
    [INLINES.HYPERLINK]: (_node: any, next: (arg0: any) => any) =>
      `<a href=${_node.data.uri}>${next(_node.content)}</a>`,
  },
}
</script>

<div class="rich-text">
  {@html documentToHtmlString(richText, RICHTEXT_OPTIONS)}
</div>

<style lang="scss">
.rich-text {
  width: 100%;
  font-size: 17px;
  line-height: 22px;
  font-weight: 300;
  letter-spacing: 0.16px;

  @media only screen and (min-width: 1024px) {
    font-size: 18px;
    line-height: 24px;
  }

  :global(p) {
    margin-top: 0;
  }

  :global(h1),
  :global(h2),
  :global(h3),
  :global(h4),
  :global(h5),
  :global(h6) {
    margin: 0;
    line-height: 1.2em;
  }

  :global(ul),
  :global(ol) {
    list-style: initial;
    padding: 20px;
  }

  :global(li) {
    margin-bottom: 30px;
    font-size: 17px;
    line-height: 22px;
    font-weight: 300;
    letter-spacing: 0.16px;

    @media only screen and (min-width: 1024px) {
      font-size: 18px;
      line-height: 24px;
    }
  }

  :global(img) {
    margin: 20px 0;
    border-radius: 16px;
    width: 100%;
    height: auto;
  }
}
</style>
